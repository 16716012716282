<template>
  <span
    class="multipleLines"
    :title="getTitle(product)"
  >{{ getSKU(product, multipleLines) }}</span>
</template>

<script setup>
import {REGION_KEYS} from '~/assets/js/constants'

const props = defineProps({
  product: {
    type: Object,
    default() {
      return null
    }
  },
  multipleLines: {
    type: Boolean,
    default() {
      return false
    }
  },
  showValuesInRow: {
    type: Boolean,
    default() {
      return false
    }
  }
})

const maxLength = 15
const app = useNuxtApp()

const getSKU = (product, multipleLines) => {
  const customerNumber = product?.customerMappings?.number
  const sku = product?.sku
  if (!props.showValuesInRow) {
    if (!product || !sku) {
      return ''
    }
    if (!customerNumber) {
      return sku.toString()
    }
    return `${sku}${multipleLines && customerNumber ? '\n' : ' '}${shortenCustomerSKU(customerNumber)}`
  } else {
    let copFnstSku = getCopFnstSku(product)
    let ownNumber = customerNumber === sku ? null : customerNumber
    if (ownNumber !== null && ownNumber !== undefined && copFnstSku !== null && copFnstSku !== undefined) {
      if (ownNumber === copFnstSku) {
        return `${ownNumber} / ${sku}`
      } else {
        return `${ownNumber} / ${copFnstSku} / ${sku}`
      }
    } else if (ownNumber !== null && ownNumber !== undefined) {
      return `${ownNumber} / ${sku}`
    } else if (copFnstSku !== null && copFnstSku !== undefined) {
      return `${copFnstSku} / ${sku}`
    } else {
      return `${sku}`
    }
  }
}

const getTitle = (product) => {
  if (product?.customerMappings?.number && product?.customerMappings?.number.length > maxLength) {
    return product?.customerMappings?.number
  }
}

const shortenCustomerSKU = (customerSku) => {
  if (customerSku) {
    if (customerSku.length > maxLength) {
      let trimmedString = customerSku.substring(0, maxLength)
      return '(' + trimmedString + '...' + ')'
    }
    return '(' + customerSku + ')'
  }
  return ''
}

const getCopFnstSku = (product) => {
  const region = app.$globalization.getRegion(false) ?? ''
  // no !== because sku is a number and copFnstSku a string,
  // only show copFnstSku when its different to the sku

  // eslint-disable-next-line eqeqeq
  if (region === REGION_KEYS.NA && product?.copFnstSku != product?.sku) {
    return product?.copFnstSku ?? null
  }
  return null
}
</script>

<style lang="scss">
  .multipleLines {
    white-space: pre;
  }
</style>
