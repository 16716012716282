import localeData from '~/config/localedata.json'

export function getCurrencySymbol(country, currency) {
  return (currency !== 'MXN' && localeData.main[country]?.numbers?.currencies?.[currency]?.['symbol-alt-narrow']) ||
  localeData.main[country]?.numbers?.currencies?.[currency]?.symbol ||
  currency
}

export function calculateTotalPrice(selectedItems, availableCertificates) {
  return selectedItems.filter(item => item.price || item.certificateUuid).reduce((total, item) => {
    let certPrice = 0
    if (item.certificateUuid) {
      certPrice = availableCertificates?.find(c => c.certificateUuid === item.certificateUuid)?.price ?? 0
    }
    return total + (item.expectedPricePer100 ? (item.expectedPricePer100 * (item.quantity / 100)) : item?.price?.price ?? 0) + certPrice
  }, 0)
}
